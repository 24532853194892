@import '../../styles.variables.scss';

.documentContent {
  max-width: calc(100vw - 400px);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: 1rem;
  }

  &__multifile_message {
    font-weight: bold;
    width: 100%;
  }

  &__multifile_autocomplete {
    width: 35vw;
    min-width: 240px;
    max-width: 480px;
    margin-right: 0.5rem;
  }

  &__navigation {
    display: flex;
    align-items: center;

    button {
      display: flex;
      margin-right: 0.5rem;
    }

    button + a {
      margin-left: 1rem;
    }
  }

  &__language-selector {
    width: 120px;
  }

  &__frame {
    margin: 0;
    padding: 0;
    flex-grow: 1;
    border: none;
  }

  &__cover {
    width: 100vw;
    height: 100vh;
    background-color: white;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__icon {
    padding-right: 0.5rem;
    width: 2rem;
    fill: $blue-noon;
  }

  &__icon_multi_file {
    padding-right: 0.5rem;
    width: 2rem;
    fill: white;
  }

  &__icon_active {
    fill: white;
  }

  &__icon_active_multi_file {
    fill: black;
  }

  &__loader {
    margin: 0.5rem 0;
    color: $info-color;
  }
}

.faq {
  &__title {
    color: $white;
    font-weight: bold;
    border-radius: 2px;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    background-color: $blue-noon;
  }

  &__row {
    display: flex;
    & div {
      margin-right: 2rem;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: 0.8rem;
  }

  &__content {
    margin-bottom: 1rem;
    padding: 0.75rem 0.5rem;
    background-color: $blue-noon-10;
  }

  &__subcontent {
    margin: 0.25rem 0;

    & p {
      margin: 0 0 0.5rem 0;
    }
  }

  &__highlighted {
    margin-bottom: 1rem;
    padding: 0.25rem;
    background-color: #ffffd1;
    border-color: black;
    border-style: solid;
    border-width: thin;
    font-size: 0.8rem;
    text-align: center;
  }
}
